import React, { useState, useContext } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { ErrorBox, SuccessBox, Submit } from "../components";
import Context from "../context";

const PaymentMethodForm = ({ onSubmit, token = false, api }) => {
  const [loading, setLoading] = useState(false);
  const [localError, setError] = useState(false);
  const { state } = useContext(Context);
  const error = state.errors.payment_method;
  const success = state.success.payment_method;

  const stripe = useStripe();
  const elements = useElements();

  async function handleSubmit(e) {
    e.preventDefault();

    setError(false);
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setLoading(false);
      return;
    }

    // Attempt to submit the Elements
    const elementResult = await elements.submit();

    if (elementResult?.error) {
      setLoading(false);
      setError(elementResult.error.message);
      return;
    }

    const clientSecret = await api.stripeCreateSetupIntent();

    // Confirm the setup if using SetupIntent
    const res = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (res?.error) {
      setLoading(false);
      console.error(error);
      setError(error.message);
    } else {
      // The payment method is successfully created and saved
      console.log("PaymentMethod created and saved");
      await onSubmit(res?.setupIntent?.payment_method);
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-primary">
        <PaymentElement />
      </div>
      <ErrorBox error={localError || error} />
      <SuccessBox success={success} />
      <div className="flex justify-end">
        <Submit
          id="payment-method-submit"
          copy="Save"
          loading={loading}
          style={{ marginTop: "10px" }}
        />
      </div>
    </form>
  );
};

export default PaymentMethodForm;
