import React from "react";
import { useForm } from "react-hook-form";
import { withStuff } from "../hocs";

const EditClientModal = ({ client, isOpen, onClose, api }) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      first_name: client.first_name,
      last_name: client.last_name,
      email: client.email,
      phone_number: client.phone_number,
    },
  });

  const onSubmit = (data) => {
    api.updateClient(client.id, data);
    onClose();
    reset(data);
    window?.location?.reload();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <h2 className="text-lg font-medium mb-4">Edit Client Info</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              id="first_name"
              name="first_name"
              {...register("first_name")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              id="last_name"
              name="last_name"
              {...register("last_name")}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              Email
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              id="email"
              name="email"
              {...register("email")}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="phone_number"
            >
              Phone Number
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              id="phone_number"
              name="phone_number"
              {...register("phone_number")}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withStuff(EditClientModal, {
  api: true,
});
