import React from "react";
import { withStuff } from "../hocs";
import { ClientIndex, Breadcrumb } from "../components";

const Clients = () => {
  return (
    <div className="font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0">
      <Breadcrumb self="Clients" />
      <div className="mt-12 w-full">
        <ClientIndex header="All Clients" search={true} />
      </div>
    </div>
  );
};

export default Clients;
