import React from "react";

const TodoIndex = ({ todos = [], hrefPrefix = "", userType }) => {
  const notEnrolledMessage = {
    header: "No plan data to share here... yet!",
    copy:
      userType === "ClientUser"
        ? "If you enroll into a plan with us at Move Health, we will display the details of that plan here."
        : "If your client enrolls into a plan with us at Move Health, we will display the financial impact of that plan here.",
    text: "red",
    color: "lred",
  };

  const formattedTodos = todos.map((todo) => (todo?.isNotEnrolled ? notEnrolledMessage : todo));

  return (
    <>
      {(formattedTodos || []).map((todo, i) =>
        todo?.href ? (
          <a key={i} className="no-underline" href={hrefPrefix + (todo.href || "#")}>
            <div
              className={`flex justify-between items-center p-3 mb-4 cursor-pointer text-move-${todo.text} rounded-md bg-move-${todo.color} ${todo.class}`}
            >
              <div>
                <div className="text-lg font-semibold">{todo.header}</div>
                <div className="text-base">{todo.copy}</div>
              </div>
              {todo.href && <i className="fa-regular fa-circle-right text-xl" />}
            </div>
          </a>
        ) : (
          <div key={i} className="no-underline">
            <div
              className={`flex justify-between items-center p-3 mb-4 text-move-${todo.text} rounded-md bg-move-${todo.color} ${todo.class}`}
            >
              <div>
                <div className="text-lg font-semibold">{todo.header}</div>
                <div className="text-base">{todo.copy}</div>
              </div>
              {todo.href && <i className="fa-regular fa-circle-right text-xl" />}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default TodoIndex;
