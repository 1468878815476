import React from "react";

import { Tooltip } from "react-tippy";

const InfoTooltip = ({ copy, pad = false, position = "right", size = "small" }) => (
  <Tooltip
    title={copy}
    position={position}
    trigger="mouseenter"
    inertia="true"
    transitionFlip="true"
    delay="0"
    arrow={true}
    size={size}
  >
    <span>
      {pad ? <span>&nbsp;&nbsp;</span> : null}
      <i className="w-4 h-4 fa-solid text-move-slate fa-info-circle ml-1 cursor-pointer" />
    </span>
  </Tooltip>
);

export default InfoTooltip;
