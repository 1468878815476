import React from "react";
import { withStuff } from "../hocs";
import { toUSD } from "../utils";

const FinancialImpact = ({ state }) => {
  const hasPolicy = state.policies.length > 0;
  if (!hasPolicy) return null;

  const majorMedicalPolicy = state.policies.find((policy) => policy.major_medical);
  const ancillaryPolicies = state.policies.filter((policy) => !policy.major_medical);

  return (
    <>
      {majorMedicalPolicy && (
        <div className="mt-4 px-2">
          <div className="font-bold mb-2">HEALTH INSURANCE PLAN IMPACT</div>
          <div className="flex items-end justify-between mt-2">
            <div>Premium</div>
            <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
            <div>{toUSD(majorMedicalPolicy.premium)}</div>
          </div>
          <div className="flex items-end justify-between mt-2">
            <div>Deductible</div>
            <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
            <div>
              {toUSD(majorMedicalPolicy.deductible)} | {toUSD(majorMedicalPolicy.deductible * 2)}
            </div>
          </div>
          <div className="flex items-end justify-between mt-2">
            <div>Max Out-of-Pocket</div>
            <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
            <div>
              {toUSD(majorMedicalPolicy.moop)} | {toUSD(majorMedicalPolicy.moop * 2)}
            </div>
          </div>
          <div className="border-b border-black my-3"></div>
          <div className="flex items-end justify-between mt-2">
            <div>Fixed Annual Cost</div>
            <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
            <div>{toUSD(majorMedicalPolicy.premium * 12)}</div>
          </div>
          <div className="flex items-end justify-between mt-2">
            <div>Additional Annual Exposure</div>
            <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
            <div>{toUSD(majorMedicalPolicy.moop * 2)}</div>
          </div>
        </div>
      )}
      {ancillaryPolicies && (
        <div className="mt-4 px-2">
          <div className="font-bold mb-2">ANCILLARY HEALTH INSURANCE PLAN IMPACT</div>
          {ancillaryPolicies.map((policy, index) => (
            <div key={policy.id} className="flex items-end justify-between mt-2">
              <div>{policy.policy_type} Premium</div>
              <div className="flex-grow border-b mx-1 border-move-slate border-dashed h-full mb-1"></div>
              <div>{toUSD(policy.premium)}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default withStuff(FinancialImpact, {
  state: true,
  api: true,
  dependency: "client",
  loader: "policies",
  effect: ({ api, state }) => {
    api.getPolicies(state.client.id);
  },
});
