import React from "react";
import { withStuff } from "../hocs";
import Logo from "../assets/logo.svg";
import { CopyButton, InfoTooltip } from "../components";
import { toUSD } from "../utils";

const colors = {
  lbp: "bronze",
  lsp: "silver",
  lgp: "gold",
};

const MarketPlaceQuote = ({ premiums, tax_credit, marketplace_unavailable, eligibility }) => {
  if (marketplace_unavailable) {
    return (
      <div className="flex flex-col rounded-md text-move-dyellow bg-move-lyellow p-3 shadow-md text-left">
        <div className="flex items-start justify-start text-move-dyellow font-semibold text-lg">
          <div>Pre-65 Market Unavailable for Quoting</div>
        </div>
        <div className="text-sm mt-2">
          Due to state based marketplace regulations some states are unavailable for quoting on our
          platform. Don’t worry, we can still help! Refer your client to Move or reach out to your
          dedicated advisor at Move so that we may assist.
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-md bg-move-lgrey p-3 shadow-md">
      <div className="flex justify-between">
        <div className="flex-col text-left">
          <div className="font-semibold text-2xl">Pre-65 Health Insurance</div>
          <p className="text-sm text-move-slate">
            Estimates shown are after tax credits (if applicable)
          </p>
        </div>
        <div className="text-right flex-col">
          <div className="font-semibold text-2xl leading-none">
            {toUSD(premiums["Silver Plan"].net_premium)}
            <span className="text-lg ml-1">/mo.</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-3">
        {Object.keys(premiums).map((key, i) => (
          <div className="flex-col text-center items-center shadow-sm rounded-lg bg-move-offwhite p-3 text-md leading-1 w-1/3 md:w-full">
            <div className={`text-md font-semibold text-move-${colors[premiums[key].name]}`}>
              {key}
            </div>
            <div className={`text-3xl font-bold text-move-${colors[premiums[key].name]}`}>
              {toUSD(premiums[key].net_premium)}
              <span className="font-semibold text-xl ml-1">/mo.</span>
            </div>
            {tax_credit != 0 ? (
              <div
                className={`text-move-${
                  colors[premiums[key].name]
                } line-through text-md font-light`}
              >
                {toUSD(premiums[key].premium)}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="flex justify-start text-sm mt-4 text-move-slate">
        <span className="mr-1">Covered:</span>
        {eligibility.map((elig, i) => {
          if (elig.type === "marketplace") {
            return (
              <div className="mr-3">
                <i class="fa-solid fa-circle-check text-move-green mr-1" />
                {elig.sex} ({elig.age})
              </div>
            );
          } else if (elig.type === "chip") {
            return (
              <div className="mr-3">
                <i class="fa-solid fa-circle-check text-move-purple mr-1" />
                {elig.sex} ({elig.age}) <InfoTooltip copy="Likely eligible for Medicaid or CHIP" />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

const MedicareQuote = (estimation) => {
  if (estimation.medicare_unavailable) {
    return (
      <div className="flex flex-col rounded-md text-move-dyellow bg-move-lyellow p-3 shadow-md text-left">
        <div className="flex items-start justify-start text-move-dyellow font-semibold text-lg">
          <div>Medicare Market Unavailable for Quoting</div>
        </div>
        <div className="text-sm mt-2">
          Some states are unavailable for quoting on our platform. Please schedule refer your client
          to Move Health so that one of our advisors can assist them.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col rrounded-md bg-move-lgrey p-3 shadow-md">
        <div className="flex justify-between">
          <div className="flex-col text-left">
            <div className="font-semibold text-2xl">Medicare</div>
          </div>
        </div>
        <div className="flex shadow-sm rounded-lg bg-move-offwhite p-3">
          <div className="flex flex-col text-left text-md leading-1 w-full space-y-2">
            <div className="flex justify-between items-center font-bold">
              <div className="text-move-dblue font-bold text-md my-1 h-14">
                Traditional Medicare
                <span className="font-light">&nbsp;(Parts A & B + Supplement)</span>
              </div>
              <div>
                {toUSD(estimation.medicare_sum)}
                <span className="text-sm ml-1">/mo.</span>
              </div>
            </div>
            <div className="flex w-full justify-between align-bottom items-center text-xs border-b border-solid border-move-grey">
              <div className="font-medium">Breakdown</div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        {elig.sex} ({elig.age})
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">Part A</span>
                <br></br>
                <span className="text-xs font-light">Hospital Insurance</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        $0<span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">Part B</span>
                <br></br>
                <span className="text-xs font-light">Medical Insurance</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        <InfoTooltip copy="Higher incomes are potentially subject to IRMAA" />
                        <span className="text-xs font-light">
                          {" "}
                          {toUSD(estimation.traditional_part_b)} + $174.70 ={" "}
                        </span>
                        {toUSD(estimation.traditional_part_b + 174.7)}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">Part D</span>
                <br></br>
                <span className="text-xs font-light">Drug Coverage</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        <InfoTooltip copy="Higher incomes are potentially subject to IRMAA" />
                        <span className="text-xs font-light">
                          {" "}
                          {toUSD(estimation.part_d_irmaa)} + {toUSD(estimation.traditional_part_d)}{" "}
                          ={" "}
                        </span>
                        {toUSD(estimation.traditional_part_d + estimation.part_d_irmaa)}{" "}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold text-move-dblue">
                  Plan G
                  <InfoTooltip copy="Plan G is assumed as it is the most widely used supplement." />
                </span>
                <br></br>
                <span className="text-xs font-light">Medicare Supplement</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        {toUSD(elig.plan_g)}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between font-medium italic align-middle items-center text-sm">
              <div className="font-medium">Out of Pocket Costs</div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        $240<span className="text-xs"> /yr.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex shadow-sm rounded-lg bg-move-offwhite p-3 mt-3">
          <div className="flex flex-col text-left text-md leading-1 w-full space-y-2">
            <div className="flex justify-between font-bold items-center">
              <div className="text-move-dred font-bold text-md my-1 h-14">
                Medicare Advantage
                <span className="font-light">&nbsp;(Part C)</span>
              </div>
              <div>
                {toUSD(estimation.advantage_sum)}
                <span className="text-sm ml-1">/mo.</span>
              </div>
            </div>
            <div className="flex w-full justify-between align-bottom items-center text-xs border-b border-solid border-move-grey">
              <div className="font-medium">Breakdown</div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        {elig.sex} ({elig.age})
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">Part A</span>
                <br></br>
                <span className="text-xs font-light">Hospital Insurance</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        $0<span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">Part B</span>
                <br></br>
                <span className="text-xs font-light">Medical Insurance</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        <InfoTooltip copy="Higher incomes are potentially subject to IRMAA" />
                        <span className="text-xs font-light">
                          {" "}
                          $174.70 + {toUSD(estimation.advantage_part_b)} ={" "}
                        </span>
                        {toUSD(estimation.advantage_part_b + 174.7)}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold text-move-dred">Part C</span>
                <br></br>
                <span className="text-xs font-light">Advantage Plan</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        {toUSD(estimation.advantage_part_c)}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between  align-middle items-center text-sm">
              <div>
                <span className="font-bold">
                  Part D
                  <InfoTooltip copy="Part D coverage is often included in a Medicare Advantage Plan (MAPD)." />
                </span>
                <br></br>
                <span className="text-xs font-light">Drug Coverage</span>
              </div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        <InfoTooltip copy="Higher incomes are potentially subject to IRMAA" />
                        <span className="text-xs font-light">
                          {" "}
                          {toUSD(estimation.advantage_part_d)} + {toUSD(estimation.part_d_irmaa)} ={" "}
                        </span>
                        {toUSD(estimation.advantage_part_d + estimation.part_d_irmaa)}{" "}
                        <span className="text-xs"> /mo.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="flex w-full justify-between align-middle font-medium italic items-center text-sm">
              <div className="font-medium">Out of Pocket Costs</div>
              <div className="flex gap-2">
                {estimation.eligibility.map((elig, i) => {
                  if (elig.type === "medicare") {
                    /*
                     ** Formatting for if html is returned and removing the
                     ** in-and-out and just keeping the separate in-network and out-of-network
                     */
                    const formattedOOP = estimation.advantage_oop
                      .replace(
                        /(\$\d{1,3}(?:,\d{3})*(?:\.\d{2})?\s*In and Out-of-network<br\s*\/?>)/g,
                        ""
                      )
                      .replace(/(<br\s*\/?>)/g, "<span class='text-xs'> /yr.</span> $1");
                    return (
                      <div className="text-right gap-1 w-36 lg:w-48">
                        <span dangerouslySetInnerHTML={{ __html: formattedOOP }}></span>
                        <span className="text-xs"> /yr.</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-start text-sm mt-4 text-move-slate">
          {estimation.eligibility.map((elig, i) => {
            if (elig.type === "medicare") {
              return (
                <div className="mr-3">
                  <i class="fa-solid fa-circle-check text-move-green mr-1" />
                  {elig.sex} ({elig.age})
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </>
  );
};

const Estimation = ({ state, isPublic = false }) => {
  const { estimation } = state;

  if (!estimation?.id) {
    return (
      <div className="font-move flex justify-center items-center h-screeen">
        <div className="flex-col w-full text-center text-move-slate py-56">
          <div className="text-xl font-base">Enter client details to create estimate</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isPublic && (
        <div className="w-full p-4 shadow-sm bg-white items-center">
          <div className=" max-w-3xl mx-auto flex justify-between items-center">
            <a href="/">
              <img src={Logo} alt="logo" height="auto" width="100px" className="block" />
            </a>
            <div className="flex justify-end items-center">
              <a
                className="text-move-teal text-sm font-bold hover:text-move-navy mr-3"
                target="_blank"
                href="https://cal.com/team/move-health-partners"
              >
                <button type="button">Schedule Meeting</button>
              </a>
              <a
                className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2"
                href="tel:800-805-8120"
              >
                <button type="button">Call (800) 805-8120</button>
              </a>
            </div>
          </div>
        </div>
      )}

      <div className={`grid gap-3 items-start w-full ${isPublic ? "mt-12 max-w-3xl mx-auto" : ""}`}>
        <div className="flex justify-between items-start pb-1 mb-4">
          <div className="flex-col justify-start items-start">
            <div className="font-move text-left mb-2 text-2xl font-bold text-move-navy">
              {" "}
              {estimation.client_first_name}'s health plan estimate
            </div>
            <div className="text-sm flex justify-start">
              <div className="mr-5">
                <i class="text-xs fa-solid fa-location-dot mr-2" />
                {estimation.zip}
              </div>
              <div className="mr-5">
                <i class="text-xs fa-solid fa-user-shield mr-2" />
                {estimation.dependents_count}
              </div>
              <div className="mr-5">
                <i class="text-xs fa-solid fa-house mr-2" />
                {estimation.household_size}
              </div>
              <div className="mr-5">
                <i class="text-xs fa-solid fa-sack-dollar mr-2" />
                {toUSD(estimation.income)}
              </div>
            </div>
          </div>
          {!isPublic && (
            <CopyButton
              copyText={estimation.share_url}
              preText="Copy Share Link"
              postText="Link Copied!"
              className="text-md font-semibold text-move-orange hover-text-move-dorange"
            />
          )}
        </div>

        {/** Savings - Only show this if they are receiving tax credits/subsidies **/}
        {estimation.eligible_for_marketplace && (
          <div className="flex flex-col rounded-md text-move-dgreen bg-move-lgreen p-3 shadow-md text-left">
            <div className="flex items-start justify-between text-move-green font-semibold text-lg">
              <div>
                Likely {estimation.tax_credit == 0 ? "not" : ""} eligible for financial help
              </div>
              <div className="text-right flex-col">
                <div className="text-lg leading-none">
                  {toUSD(estimation.tax_credit)}
                  <span className="text-xs ml-1">/mo.</span>
                </div>
              </div>
            </div>
            <div className="text-sm mt-2">
              This is an estimate of how much of the monthly premium will be paid by the government.
              Based on the information provided, the household income is equal to{" "}
              <span className="font-bold text-md">{estimation.percent_of_fpl}%</span> of the poverty
              level. To determine the actual amount of financial help, schedule a call with a Move
              Health advisor.
            </div>
          </div>
        )}

        {/** All Medicaid/CHIP - Only show this if the entire family is Medicaid/CHIP eligible and under 65**/}

        {estimation.eligible_for_medicaid && (
          <div className="flex flex-col rounded-md text-move-dpurple bg-move-lpurple p-3 shadow-md text-left">
            <div className="flex items-start justify-between text-move-purple font-semibold text-lg">
              <div>Likely eligible for Medicaid</div>
            </div>
            <div className="text-sm mt-2">
              The entire family may be eligible for Medicaid or CHIP, paid for by the government.
              Based on the information provided, the household is likely eligible for Medicaid.
              Medicaid is a health coverage program run by both the States and Federal government.
              Medicaid coverage varies from state to state, but out-of-pocket costs are generally
              very low. Tobacco use is not taken into account in Medicaid eligibility. These results
              are estimates based on your income and where you live. To determine the actual amount
              of financial help, schedule a call with a Move Health advisor.
            </div>
          </div>
        )}
        {estimation.eligible_for_marketplace && <MarketPlaceQuote {...estimation} />}

        {estimation.eligible_for_medicare && <MedicareQuote {...estimation} />}
      </div>
    </>
  );
};

export default withStuff(Estimation, {
  api: true,
  state: true,
  loader: "update_estimation",
});
