import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { withStuff } from "../hocs";
import EditClientModal from "../components/edit_client_modal";
import {
  Breadcrumb,
  AdvisorCard,
  Booking,
  ActivityIndex,
  ClientStatus,
  TodoIndex,
  FinancialImpact,
} from "../components";

const ClientShow = ({ state, api }) => {
  const client = state.client || {};
  const [sent, setSent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function sendInviteEmail() {
    if (sent) return;
    if (window.confirm("Are you sure you want to send this client a referral email?")) {
      api.sendClientInvite(client.id);
      setSent(true);
    }
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div className="font-move mt-3 mx-3 xl:mx-0 ml-5 xl:ml-0">
      <Breadcrumb self="Client" />
      <div className="xl:flex gap-3 mt-12 max-w-7xl items-start">
        <div className="w-full xl:w-2/3 rounded-md shadow-sm bg-move-lgrey p-3">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium flex items-center">
              {client.name}
              <i className="text-xs ml-2 fa fa-pencil cursor-pointer" onClick={toggleModal} />
            </h2>
            <ClientStatus status={client.status} />
          </div>
          <div className="text-sm flex items-center mt-2 gap-2">
            <i className="text-move-blue fa-regular fa-envelope" />
            <a className="no-underline" href={`mailto:${client.email}`}>
              {client.email}
            </a>
          </div>
          <div className="text-sm flex items-center  mt-2 gap-2">
            <i className="text-move-blue fa-solid fa-phone" />
            <a className="no-underline" href={`tel:${client.phone_number}`}>
              {client.phone_number}
            </a>
          </div>
          {sent ? (
            <div className="text-sm flex items-center  mt-2 gap-2">
              <i className="text-move-green fa-regular fa-paper-plane" />
              <a className="block no-underline" href="#">
                Referral email sent!
              </a>
            </div>
          ) : (
            <div className="text-sm flex items-center mt-2 gap-2">
              <i className="text-move-blue fa-regular fa-paper-plane" />
              <a className="block no-underline" href="#" onClick={sendInviteEmail}>
                Send referral email
              </a>
            </div>
          )}
          {client.client_notes && (
            <div className="mt-3">
              <strong>Client Notes</strong>
              <p>{client.client_notes}</p>
            </div>
          )}
          <div className="border-t-2 border-solid border-move-slate mt-3 pt-4">
            <TodoIndex todos={state.client.todos} userType={state.user.type} />
            <FinancialImpact />
          </div>
        </div>
        <div className="w-full xl:w-1/3 mt-6 xl:mt-0">
          <Booking clientId={client.id} />
          <div className="mt-6">
            <ActivityIndex clientId={client.id} />
          </div>
          <AdvisorCard copy="HEALTH INSURANCE AGENT" advisor={state.client.health_advisor} />
        </div>
      </div>
      <EditClientModal client={client} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default withStuff(ClientShow, {
  state: true,
  api: true,
  loader: "client",
  effect: ({ api, match }) => {
    api.getClient(match.params.id);
  },
});
