import React from "react";
import { Select, CheckInput, Currency, RadioInput, InfoTooltip } from ".";
import { Tooltip } from "bootstrap";

const Inputs = ({
  inputs,
  defaultObject = {},
  col = "6",
  style,
  errors = {},
  ...config
}) => {
  function buildInputGroup(input, i) {
    if (Array.isArray(input)) {
      return (
        <div className="flex justify-between gap-3" key={i}>
          {input.map((input, i) => buildInputGroup(input, i))}
        </div>
      );
    } else {
      const { label } = input;

      return (
        <div className={`col-md-${col} col-xs-12`} key={i}>
          <div className={input.type !== "hidden" ? "input-primary" : ""}>
            {label && input.type != "hidden" && (
              <label htmlFor={input.id || input.name}>
                {label}
                {input.tooltip && <InfoTooltip copy={input.tooltip} />}
              </label>
            )}
            {buildInput(
              input,
              !!errors[input.error_key || input.name || input.key],
              input.defaultValue || defaultObject[input.name || input.key]
            )}
            {errors[input.error_key || input.name || input.key] && (
              <div className="error">
                {errors[input.error_key || input.name || input.key]}
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  function buildInput(input, error = false, defaultValue = null) {
    if (input.type === "select") {
      return (
        <Select
          _ref_={input._ref_}
          {...input}
          className={`${error && "has-error"}`}
          defaultValue={defaultValue}
          label=""
          {...config}
        />
      );
    } else if (input.type === "currency") {
      return (
        <Currency
          _ref_={input._ref_}
          {...input}
          className={`${error && "has-error"}`}
          defaultValue={defaultValue}
          label=""
          {...config}
        />
      );
    } else if (input.type === "radio") {
      return (
        <RadioInput
          _ref_={input._ref_}
          {...input}
          className={`${error && "has-error"}`}
          defaultValue={defaultValue}
          label=""
          {...config}
        />
      );
    } else if (input.type === "check") {
      return (
        <CheckInput
          {...input}
          defaultValue={defaultValue}
          _ref_={input._ref_}
          className={`${error && "has-error"}`}
          {...config}
        />
      );
    } else if (input.type === "textarea") {
      return (
        <textarea
          id={input.id}
          name={input.name || input.key}
          placeholder={input.placeholder}
          rows={input.rows}
          defaultValue={input.defaultValue || defaultObject[input.name]}
          ref={input._ref_}
          className={`${error && "has-error"}`}
        />
      );
    } else {
      return (
        <input
          {...input}
          id={input.id || input.name}
          className={`${input.required && "required"} ${error && "has-error"}`}
          name={input.name || input.key}
          ref={input._ref_}
          type={input.type || "text"}
          defaultValue={defaultValue}
          onChange={input.onChange}
          placeholder={input.placeholder}
          style={input.style}
        />
      );
    }
  }

  function icon(i) {
    if (i)
      return (
        <span>
          <i className={i} />
          &nbsp;&nbsp;
        </span>
      );
  }

  return (
    <div className="row items-center justify-center" style={style}>
      {inputs.map((input, i) => buildInputGroup(input, i))}
    </div>
  );
};

export default Inputs;
