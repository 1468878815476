import React from "react";
import { InfoTooltip } from "../components";

const ClientStatus = ({ status }) => {
  let color;
  let tooltipMessage;
  switch (status) {
    case "referred":
      color = "blue";
      tooltipMessage =
        "Your client has been referred to Move Health. Your Move Health team member has reached out to your client to create an account and schedule an appointment.";
      break;
    case "scheduled":
      color = "yellow";
      tooltipMessage = "Your client has scheduled an appointment with Move Health";
      break;
    case "pending":
      color = "purple";
      tooltipMessage =
        "Your client has enrolled into coverage with the assistance of Move Health, but the coverage is not yet active.";
      break;
    case "enrolled":
      color = "green";
      tooltipMessage = "Your client has enrolled into coverage with the assistance of Move Health.";
      break;
    case "cancelled":
      color = "red";
      tooltipMessage =
        "The coverage your client enrolled into with the assistance of Move Health has been cancelled.";
      break;
    case "deferred":
      color = "yellow";
      tooltipMessage =
        "Your client is more than 6 months out from a coverage decision. Next steps are in place, but may be several months away.";
      break;
    case "passed":
      color = "yellow";
      tooltipMessage =
        "Your client has decided to seek healthcare planning help elsewhere and is no longer working with Move Health.";
      break;
    case "renewal":
      color = "yellow";
      tooltipMessage = "Your client's coverage is eligible for renewal. We'll reach out to them.";
      break;
    case "terminated":
      color = "red";
      tooltipMessage = "Your client's coverage has been terminated by their carrier.";
      break;
    case "unknown":
      color = "gray";
      tooltipMessage = "This is only here because it is a status within Healthsherpa.";
      break;
    case "follow up":
      color = "blue";
      tooltipMessage =
        "Your client is in progress with Move Health and they're working through the normal process.";
      break;
    default:
      color = "gray";
      tooltipMessage = "Your client has scheduled an appointment with Move Health";
      break;
  }
  return (
    <div className={`text-move-d${color} rounded-md bg-move-l${color} px-2 py-.75`}>
      <span className="text-xs font-semibold uppercase">{status}</span>
      <InfoTooltip size="small" copy={tooltipMessage} position="top" />
    </div>
  );
};

export default ClientStatus;
